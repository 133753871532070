<template>
  <div class="transaction-item flex justify-between w-100 items-center">
    <span
      v-if="
        transaction.object &&
        (transaction.object === 'payment_intent' || transaction.object === 'charge')
      "
    >
      <a
        @click="() => openInvoice(transaction.metadata.invoiceId)"
        v-tooltip="`Click to edit invoice #${transaction.metadata.invoiceId}`"
      >
        {{
          transaction.metadata?.invoiceId
            ? $f.truncate(transaction.metadata.invoiceId.toString(), 12)
            : ''
        }}
      </a>
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.balancePlatform }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.accountHolder }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.balanceAccount }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.transferId }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.transactionId }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.category }}
    </span>
    <span v-if="transaction.type && transaction.type === 'balanceAccount'" class="text-center">
      <Chip
        :label="$f.capitalize(getStatus(transaction))"
        :pt="getChipPT(transaction.status)"
        :class="statusClass(transaction.status)"
        v-tooltip="
          transaction.failure_message ||
          (transaction.next_action && transaction.next_action.type.replaceAll('_', ' '))
        "
      >
      </Chip>
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.type }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ $f.datetime(transaction.bookingDate) }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.bookingDateTimeZone }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ $f.datetime(transaction.valueDate) }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.valueDateTimeZone }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.currency }}
    </span>
    <span>
      {{ $f.currency(formatAmount(transaction.amount)) }}
      <span class="text-sm">{{ $f.uppercase(transaction.currency) }}</span>
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.reference }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.counterpartyIban }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.counterpartyBic }}
    </span>
    <span v-if="transaction.type === 'balanceAccount'">
      {{ transaction.counterpartyAccountNumber }}
    </span>
    <span class="text-center" v-if="transaction.type === 'payment' && transaction.fee_details">
      {{
        $f.currency(
          isPayfacEnabled
            ? transaction.fee_details[0].amount
            : transaction.fee_details[0].amount / 100
        )
      }}
      {{ $f.uppercase(transaction.fee_details[0].currency) }}
    </span>
    <span
      class="text-center"
      v-if="
        transaction.object &&
        (transaction.object === 'payment_intent' || transaction.object === 'charge')
      "
    >
      {{
        $f.currency(
          isPayfacEnabled
            ? transaction.application_fee_amount || 0
            : (transaction.application_fee_amount || 0) / 100
        )
      }}
      {{ $f.uppercase(transaction.currency) }}
    </span>
    <span
      class="text-center"
      v-if="
        transaction.object &&
        (transaction.object === 'payment_intent' || transaction.object === 'charge')
      "
    >
      {{ $f.currency(isPayfacEnabled ? transaction.serviceFee || 0 : transaction.serviceFee || 0) }}
      {{ $f.uppercase(transaction.currency) }}
    </span>
    <span class="text-center">
      {{ $f.datetime(transaction.created) }}
    </span>
    <span
      class="text-center"
      v-if="transaction.type === 'payout' || transaction.object === 'payout'"
    >
      {{ $f.datetime(transaction.arrival_date) }}
    </span>
    <span class="text-center" v-if="transaction.object === 'payout'">
      {{ transaction.priority || 'regular' }}
    </span>
    <span v-if="transaction.type && transaction.type !== 'balanceAccount'" class="text-center">
      <Chip
        :label="$f.capitalize(getStatus(transaction))"
        :pt="getChipPT(transaction.status)"
        :class="statusClass(transaction.status)"
        v-tooltip="
          transaction.failure_message ||
          (transaction.next_action && transaction.next_action.type.replaceAll('_', ' '))
        "
      >
      </Chip>
    </span>
  </div>
</template>

<script>
import PayfacMixin from '../PayfacMixin'
import Chip from 'primevue/chip'

export default {
  props: {
    transaction: {
      type: Object
    }
  },
  components: {
    Chip
  },
  methods: {
    getChipPT(status) {
      const res = {
        token: {
          class: [this.statusClass(status)]
        }
      }
      return res
    },
    /**
     * Map status to better reflect our terminology
     * @param {object} transaction
     */
    getStatus(transaction) {
      if (transaction.type === 'payment' && transaction.status === 'pending') return 'Processing'
      return transaction.type === 'payout' && transaction.status === 'available'
        ? 'Deposited'
        : transaction.status.replaceAll('_', ' ')
    },
    statusClass(status) {
      let className = 'bg-dark'
      switch (status.toLowerCase()) {
        case 'available':
          className = 'bg-success'
          break
        case 'succeeded':
          className = 'bg-success'
          break
        case 'completed':
          className = 'bg-success'
          break
        case 'paid':
          className = 'bg-success'
          break
        case 'approved':
          className = 'bg-success'
          break
        case 'processing':
          className = 'bg-level-yellow'
          break
        case 'pending':
          className = 'bg-level-yellow'
          break
        case 'verified':
          className = 'bg-level-yellow'
          break
        case 'intransit':
          className = 'bg-level-yellow'
          break
        case 'inprogress':
          className = 'bg-level-yellow'
          break
        case 'cancelled':
          className = 'bg-warning'
          break
        case 'expired':
          className = 'bg-warning'
          break
        case 'declined':
          className = 'bg-danger'
          break
        case 'failed':
          className = 'bg-danger'
          break
        default:
          className = 'bg-dark'
      }
      return className
    },
    /**
     * Format the amount for display
     */
    formatAmount() {
      if (this.isPayfacEnabled && this.transaction.type !== 'payout') return this.transaction.amount
      if (this.transaction.amount < 0) {
        return (this.transaction.amount * -1) / 100
      }
      return this.transaction.amount / 100
    },
    /**
     * When clicking on the invoice id open up the invoice
     * @param {string} id
     */
    openInvoice(id) {
      return this.$store.dispatch('modal/open', {
        modal: 'Invoice',
        objects: [
          {
            invoice_id: id
          }
        ]
      })
    }
  },
  mixins: [PayfacMixin]
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.transaction-item {
  > span {
    width: 100%;
    display: block;
  }
}
</style>
