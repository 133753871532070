<template>
  <div v-if="!loading" class="w-100">
    <div
      v-if="type !== 'balanceAccount' && filters && filters.length > 0"
      class="transaction-list--filter flex justify-end mb-2"
    >
      <choose
        :static-set="filters"
        v-model="status"
        @input="(v) => onSelectStatus(v)"
        :value="status"
        placeholder="Select status"
        btnClass="sm info"
      />
    </div>
    <div
      v-if="response && response.data && response.data.length > 0 && !loading"
      class="list-group-item transaction-header bg-transparent border-0 flex justify-between w-100 items-center"
    >
      <span
        v-if="type === 'payment' || type === 'intent' || type === 'charge' || type === 'failed'"
        class="text-sm text-bold"
      >
        <u>Invoice</u>
      </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold">
        <u>Balance Platform</u>
      </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold">
        <u>Account Holder</u>
      </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold">
        <u>Balance Account</u>
      </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Transfer Id</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold">
        <u>Transaction Id</u>
      </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Category</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Status</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Type</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Booking Date</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Time Zone</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Value Date</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Time Zone</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Currency</u> </span>
      <span class="text-sm text-bold"> <u>Amount</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold"> <u>Reference</u> </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold">
        <u>Counterparty BIC</u>
      </span>
      <span v-if="type === 'balanceAccount'" class="text-sm text-bold">
        <u>Counterparty account number</u>
      </span>
      <span
        v-if="type === 'payment' || type === 'intent' || type === 'charge' || type === 'failed'"
        class="text-sm text-bold text-center"
      >
        <u>Client Fee</u>
      </span>
      <span
        v-if="type === 'payment' || type === 'intent' || type === 'charge' || type === 'failed'"
        class="text-sm text-bold text-center"
      >
        <u>Service Fee</u>
      </span>
      <span v-if="type !== 'balanceAccount'" class="text-sm text-bold text-center">
        <u>Date</u>
      </span>
      <span v-if="type === 'payout' || type === 'paid'" class="text-sm text-bold text-center">
        <u>Estimated Arrival</u>
      </span>
      <span v-if="type === 'payout'" class="text-sm text-bold text-center"> <u>Priority</u> </span>
      <span v-if="type !== 'balanceAccount'" class="text-sm text-bold text-center">
        <u>Status</u>
      </span>
    </div>
    <CardList
      class="tab-section"
      v-if="response && response.data && response.data.length > 0 && !loading"
    >
      <div class="transaction-ctn mb-2 p-3 rounded" v-for="item in response.data" :key="item.id">
        <Transaction :transaction="item" />
      </div>
    </CardList>
    <div v-if="response && loading" class="loader flex items-center justify-center">
      <spinner class="mr-4" :loading="1" size="2em" />
      <p>Loading..</p>
    </div>
    <p
      class="text-muted p-2 text-center"
      v-if="response && response.data && response.data.length === 0"
    >
      No results
    </p>

    <div v-if="type !== 'balanceAccount'" class="mt-3 flex justify-end gap-2">
      <Btn :disabled="!response || page === 1" @click="() => loadMore(type, 'previous', status)">
        Previous
      </Btn>
      <Btn
        :disabled="!response || !response.has_more"
        @click="() => loadMore(type, 'next', status)"
      >
        Next
      </Btn>
    </div>
  </div>
  <div v-else class="w-100 flex justify-center">
    <spinner class="transaction-list-loader" :loading="1" size="2em" />
  </div>
</template>

<script>
import Transaction from './Transaction.vue'
import CounterpartyMixin from '../counterparty/CounterpartyMixin'

export default {
  data() {
    return {
      response: null,
      isloading: false,
      cached: {},
      status: this.defaultFilter || '',
      page: 1
    }
  },
  props: {
    type: {
      type: String
    },
    balanceAccount: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: String
    }
  },
  components: {
    Transaction
  },
  async mounted() {
    this.handleFetchTransactions()
  },
  methods: {
    /**
     * When changing the status
     */
    onSelectStatus() {
      this.handleFetchTransactions()
    },
    /**
     * Store results in the cache
     */
    storeCache() {
      this.cached[this.page] = this.response
    },
    /**
     * Retrieve results from the cache
     */
    getCache() {
      return this.cached[this.page]
    },
    /**
     * Store logic to handle the pagination for cursor based and basic
     * @param {string} type
     * @param {string} action
     */
    async loadMore(type, action = 'next') {
      const current = this.response
      if (!current || !current.data) return
      if (this.isloading) return
      const filter = {
        type,
        balanceAccount: this.$props.balanceAccount || null,
        order: `${type == 'payout' ? 'transfer_time_created' : 'transaction_time_created'} desc`
      }

      if (action === 'next') {
        this.page += 1
        if (this.response.next_page) {
          filter.starting_after = this.response.next_page
        } else {
          const lastItem = current.data.slice(-1).pop()
          const startingAfter = lastItem.id
          filter.starting_after = startingAfter
        }
      } else {
        this.page -= 1
        if (this.response.previous_page) {
          filter.starting_after = this.response.previous_page
        } else {
          const firstItem = current.data[0]
          const endingBefore = firstItem.id
          if (this.page > 1) filter.ending_before = endingBefore
        }
      }

      filter.page = this.page
      // cache the results
      const cache = this.getCache()
      if (cache) {
        this.response = cache
        return
      }
      if (this.status) filter.status = this.status
      this.isloading = true
      const { payload } = await this.fetchTransactions(filter)
      this.response = payload
      this.storeCache()
      this.isloading = false
    },
    /**
     * Handle fetching the transactions through the api
     * Passing the status and type for filtering
     */
    async handleFetchTransactions() {
      try {
        this.isloading = true
        const { payload } = await this.fetchTransactions({
          type: this.type,
          status: this.status,
          order: `${this.type == 'payout' ? 'transfer_time_created' : 'transaction_time_created'} desc`,
          balanceAccount: this.$props.balanceAccount || null
        })
        this.response = payload
        this.storeCache()
        this.isloading = false
      } catch (e) {
        console.log(e, 'e')
      }
      this.isloading = false
    }
  },
  mixins: [CounterpartyMixin],
  watch: {
    reload() {
      if (this.reload === true) this.handleFetchTransactions()
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.transaction-header {
  > span {
    display: block;
    width: 100%;
  }
}
.transaction-ctn {
  background-color: #f5f8f7;
}
.tab-section {
  box-shadow: none !important;
  overflow: inherit;
  .list-group-item {
    border-radius: 0.5em !important;
    box-shadow: $shadow;
  }
}
.transaction-list-loader {
  height: 100%;
  width: 100%;
}
.card.card--container .card-body .content .card-text > *:not(:first-child) {
  margin-top: 0 !important;
}
</style>
