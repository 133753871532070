<template>
  <div class="flex flex-col w-full bg-gray-50 min-h-full">
    <div class="sticky top-0 bg-gray-50 z-10">
      <div v-if="Object.keys(fieldErrors).length > 0" class="px-6 mb-6">
        <div class="bg-red-50 border-l-2 border-red-500 p-4">
          <div class="flex">
            <font-awesome-icon icon="exclamation-circle" class="text-red-500 mr-3" />
            <div>
              <p class="text-red-800 font-medium">Please correct the following errors:</p>
              <ul class="mt-2 list-disc list-inside text-red-700">
                <li v-for="(error, field) in fieldErrors" :key="field">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="text-base font-medium mb-4 text-center">Transfer funds</h3>

    <div class="flex flex-col flex-1 px-6">
      <div class="flex flex-col items-center mb-8">
        <div class="flex justify-center items-baseline">
          <field
            v-model="amount"
            @input="checkMax"
            format="currency"
            placeholder="$ 0.00"
            class="text-4xl border-none p-0 w-40 text-center focus:ring-0 focus:outline-none"
            @blur="handleFieldValidation('amount')"
            :class="{ 'border-red-500': fieldErrors.amount }"
          />
          <span class="text-3xl text-gray-500">USD</span>
        </div>
        <div class="text-sm text-gray-500 mt-1">
          Available balance: ${{ formatCurrency(sourceBalance?.total_balance) }}
        </div>
      </div>

      <div class="!w-full bg-white rounded-lg mb-4">
        <span class="text-sm text-gray-400 p-2">From</span>
        <choose
          v-model="sourceAccountId"
          @input="(v) => setBalanceSource(v)"
          :full="true"
          :class="{ 'border-red-500': fieldErrors.sourceAccountId }"
          :allowCreate="false"
          :allowDeselect="false"
          :forceNotEmpty="true"
          :staticSet="sourceAccounts"
        >
          <template #default="{ text }">
            <Btn @click="open" size="lg" class="!border-0 w-full" severity="tertiary">
              {{ text }}
              <font-awesome-icon icon="chevron-down" class="ml-2" />
            </Btn>
          </template>
        </choose>
      </div>

      <div class="!w-full bg-white rounded-lg mb-4">
        <span class="text-sm text-gray-400 p-2">To</span>
        <choose
          v-model="accountId"
          @input="(v) => setAccount(v)"
          :full="true"
          :disabled="!sourceAccountId"
          :class="{ 'border-red-500': fieldErrors.accountId }"
          :allowCreate="false"
          :allowDeselect="false"
          :forceNotEmpty="true"
          :staticSet="filteredBankAccounts"
        >
          <template #default="{ text }">
            <Btn @click="open" size="lg" class="!border-0 w-full" severity="tertiary">
              {{ text }}
              <font-awesome-icon icon="chevron-down" class="ml-2" />
            </Btn>
          </template>
        </choose>
      </div>

      <div class="text-right mt-1">
        <btn-group>
          <Btn
            @click="confirmPayout"
            :disabled="!verified || hasErrors"
            size="lg"
            severity="primary-black"
            class="text-nowrap"
            :loading="creating"
          >
            <span class="hidden md:inline">Confirm</span>
          </Btn>
          <Btn size="lg" severity="secondary" @click="$emit('close')">Cancel</Btn>
        </btn-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { z } from 'zod'
import { get } from 'lodash'
import { useFormFields } from '@/composables/useFormFields'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  accounts: {
    type: Array,
    default: () => []
  },
  balances: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['successful', 'error', 'close'])
const store = useStore()

const amount = ref(0)
const account = ref(null)
const accountId = ref(null)
const bankAccounts = ref([])
const sourceAccounts = ref([])
const sourceAccount = ref(null)
const sourceBalance = ref(null)
const sourceAccountId = ref(null)
const creating = ref(false)

const fields = {
  sourceAccountId: {
    name: 'Source Account',
    path: 'sourceAccountId',
    schema: z.union([
      z.string().nonempty({ message: 'Source account is required' }),
      z.number().refine((val) => !isNaN(val), { message: 'Source account is required' })
    ])
  },
  accountId: {
    name: 'Destination Account',
    path: 'accountId',
    schema: z.union([
      z.string().nonempty({ message: 'Destination account is required' }),
      z.number().refine((val) => !isNaN(val), { message: 'Destination account is required' })
    ])
  },
  amount: {
    name: 'Amount',
    path: 'amount',
    schema: z.number().positive('Amount must be greater than 0'),
    customValidation: (value, formData) => {
      if (value > formData.sourceBalance?.total_balance) {
        return `Amount cannot exceed available balance of $${formData.sourceBalance.total_balance}`
      }
      return null
    }
  }
}

const { errors: fieldErrors, validateField, validateAll, handleSubmit } = useFormFields(fields)

const handleFieldValidation = (fieldName) => {
  const value = get(
    {
      sourceAccountId: sourceAccountId.value,
      accountId: accountId.value,
      amount: amount.value,
      sourceBalance: sourceBalance.value
    },
    fields[fieldName].path
  )

  validateField(fieldName, value, {
    sourceBalance: sourceBalance.value
  })
}

const validateForm = () => {
  const allData = {
    sourceAccountId: sourceAccountId.value,
    accountId: accountId.value,
    amount: amount.value
  }

  const schema = z.object({
    sourceAccountId: fields.sourceAccountId.schema,
    accountId: fields.accountId.schema,
    amount: fields.amount.schema
  })

  return validateAll(allData, schema)
}

const hasErrors = computed(() => {
  return Object.keys(fieldErrors.value).length > 0
})

const verified = computed(() => {
  return (
    account.value &&
    amount.value &&
    accountId.value &&
    amount.value > 0 &&
    amount.value <= sourceBalance.value?.total_balance
  )
})

const filteredBankAccounts = computed(() => {
  if (!sourceAccountId.value) return []

  return bankAccounts.value.filter((account) => {
    return Number(account.value) !== Number(sourceAccountId.value)
  })
})

const checkMax = (value) => {
  if (sourceBalance.value?.total_balance && value > sourceBalance.value.total_balance) {
    fieldErrors.value.amount = `Amount cannot exceed available balance of $${sourceBalance.value.total_balance}`
    amount.value = sourceBalance.value.total_balance
  } else {
    delete fieldErrors.value.amount
  }
}

const setAccount = (v) => {
  if (v) {
    account.value = props.accounts.find((acc) => acc.id === Number(v))
    accountId.value = `${account.value.name ?? ''} (**** ${account.value.last4 ?? '****'})`
  } else {
    account.value = null
    accountId.value = null
  }
  handleFieldValidation('accountId')
}

const setBalanceSource = (v) => {
  accountId.value = null
  if (v) {
    sourceAccount.value = props.accounts.find((acc) => acc.id === Number(v))
    sourceBalance.value = props.balances.find((balance) => balance.virtual_account_id === Number(v))
    sourceAccountId.value = sourceBalance.value.virtual_account_id
  } else {
    sourceAccount.value = null
    sourceBalance.value = null
    sourceAccountId.value = null
  }
  handleFieldValidation('sourceAccountId')
}

const getSourceData = () => {
  const sourceExternalId = sourceAccount.value.external_id
  const sourceBalanceAccount = props.balances.find(
    (balance) => balance.virtual_account_id === sourceAccount.value.id
  )
  let isSourcePaymentInstrument = sourceExternalId.startsWith('PI')
  const sourceType = isSourcePaymentInstrument ? 'paymentInstrument' : 'balanceAccount'
  const sourceBalanceAccountId = sourceBalanceAccount.virtual_account_external_token

  return {
    sourceType,
    sourceExternalId,
    sourceBalanceAccountId
  }
}

const getDestinationData = () => {
  const destinationExternalId = account.value.external_id
  const destinationBalanceAccount = props.balances.find(
    (balance) => balance.virtual_account_id === account.value.id
  )
  let isDestinationPaymentInstrument = destinationExternalId.startsWith('PI')
  const destinationType = isDestinationPaymentInstrument ? 'balanceAccount' : 'transferInstrument'
  const destinationBalanceAccountId = destinationBalanceAccount.virtual_account_external_token

  return {
    destinationType,
    destinationExternalId,
    destinationBalanceAccountId
  }
}

const submitPayout = async () => {
  const { sourceType, sourceExternalId, sourceBalanceAccountId } = getSourceData()
  const { destinationType, destinationExternalId, destinationBalanceAccountId } =
    getDestinationData()

  const payoutData = {
    amount: amount.value * 100,
    currency: store.state.session.company.currency_iso,
    sourceType,
    sourceExternalId,
    sourceBalanceAccountId,
    destinationType,
    destinationExternalId,
    destinationBalanceAccountId
  }

  const { payload } = await store.dispatch('ajax', {
    path: 'payfac/createPayout',
    data: payoutData
  })

  if (payload.error) {
    throw new Error(payload.error.message)
  }

  return payload
}

const confirmPayout = () => {
  handleSubmit(
    submitPayout,
    validateForm,
    () => {
      store.dispatch('alert', {
        message: 'Payout was successfully created.',
        error: false
      })
      emit('successful')
    },
    (error) => {
      store.dispatch('alert', {
        message: error.message || 'You have insufficient funds for this transfer.',
        error: true
      })
      emit('error', error)
    }
  )
}

onMounted(() => {
  bankAccounts.value = props.accounts.map((a) => ({
    value: a.id,
    text: `${a.name} (**** ${a.last4})`
  }))
  sourceAccounts.value = bankAccounts.value.filter((ba) => {
    const account = props.balances.find((balance) => balance.virtual_account_id === ba.value)
    return account.virtual_account_type === props.type
  })

  const defaultBalance = props.balances.find(
    (balance) => balance.virtual_account_type === props.type
  )
  if (defaultBalance) {
    sourceAccount.value = props.accounts.find(
      (account) => account.id === defaultBalance.virtual_account_id
    )
    sourceBalance.value = defaultBalance
    sourceAccountId.value = defaultBalance.virtual_account_id
  }
})

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value || 0)
}
</script>

<style lang="scss" scoped>
.field,
.choose {
  &.border-red-500 {
    border-color: $deep-red-500 !important;
  }
}
</style>
