import { ZodError } from 'zod'
import type { ZodType } from 'zod'
import { ref } from 'vue'
import type { FormFields } from '@/types/forms'

export function useFormFields(fields: FormFields) {
  const errors = ref<Record<string, string>>({})
  const submitError = ref<string | null>(null)

  const formatErrorMessage = (error: ZodError) => {
    return error.errors.map(err => {
      const fieldPath = err.path.join('.')
      const field = Object.values(fields).find(f => f.path === fieldPath)
      
      if (field) {
        if (err.code === 'invalid_type') {
          return `${field.name} must be a ${err.expected}`
        }
        return err.message
      }

      // Handle nested paths
      for (const f of Object.values(fields)) {
        if (fieldPath.includes(f.path)) {
          if (err.code === 'invalid_type') {
            return `${f.name} must be a ${err.expected}`
          }
          return err.message
        }
      }

      // Fallback to the original error message
      return err.message
    })
  }

  const validateField = (fieldName: string, value: any, formData: any): boolean | undefined => {
    const field = fields[fieldName]
    if (!field) return

    try {
      field.schema.parse(value)
      if (field.customValidation) {
        const customError = field.customValidation(value, formData)
        if (customError) {
          errors.value[field.path] = customError
          return false
        }
      }
      delete errors.value[field.path]
      return true
    } catch (error) {
      if (error instanceof ZodError) {
        const [formattedError] = formatErrorMessage(error)
        errors.value[field.path] = formattedError
        return false
      }
    }
  }

  const validateAll = (data: Record<string, any>, schema: ZodType): boolean => {
    try {
      schema.parse(data)
      errors.value = {}
      return true
    } catch (error) {
      if (error instanceof ZodError) {
        const formattedErrors = formatErrorMessage(error)
        errors.value = error.errors.reduce<Record<string, string>>((acc, err, index) => {
          acc[err.path.join('.')] = formattedErrors[index]
          return acc
        }, {})
      }
      return false
    }
  }

  const handleSubmit = async <T>(
    submitFn: () => Promise<T>,
    validateFn: () => boolean,
    onSuccess?: (data: T) => void,
    onError?: (error: Error) => void
  ): Promise<void> => {
    if (!validateFn()) {
      return
    }

    submitError.value = null

    try {
      const result = await submitFn()
      if (onSuccess) {
        onSuccess(result)
      }
    } catch (error) {
      const err = error as Error
      submitError.value = JSON.stringify(err, null, 2)
      if (onError) {
        onError(err)
      }
    }
  }

  return {
    errors,
    submitError,
    validateField,
    validateAll,
    handleSubmit
  }
} 