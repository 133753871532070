<template>
  <section>
    <h2 class="mb-4">Required actions</h2>
    <div class="row">
      <div class="col-12" v-if="combined && combined.length > 0">
        <div class="field-hashes card-h-full" :flat="true">
          <p class="mb-2">Add or correct account details:</p>
          <div class="grid w-full mt-2">
            <Danger :key="`${hash}-${i}`" v-for="(hash, i) in combined">
              {{ hash.message || hash }}
            </Danger>
          </div>
        </div>
      </div>
      <div v-else class="border w-100 text-center rounded border-cool-gray-300 p-4 my-4">
        <p class="text-muted flex justify-center">
          No information required.
          <help class="ml-2">
            <template #title> Is your account unverified?</template>
            If you have just onboarded and your account is marked as unverified please wait a day or
            so. It can take time for us to verify your account. If your account is still not
            verified please reach out to support.
          </help>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import CounterpartyMixin from '../CounterpartyMixin'

export default {
  name: 'CounterpartyRequirements',
  props: {
    owners: {
      type: Array,
      default: () => []
    },
    merchant: {
      type: Object,
      default: () => {}
    },
    counterparty: {
      type: Object,
      default: () => {}
    },
    requirements: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      documentVerificationStatus: null
    }
  },
  methods: {
    onDocumentVerified() {}
  },
  computed: {
    combined() {
      /**
       * For now we want the payment and payout requirements combined
       */
      return [
        ...(this.requirements?.paymentRequirements ?? []),
        ...(this.requirements?.payoutRequirements ?? [])
      ]
    }
  },
  mixins: [CounterpartyMixin]
}
</script>

<style lang="scss" rel="stylesheet/scss">
.warning,
.danger {
  .card-body .content {
    text-align: center !important;
    .button--container {
      margin: 0 auto;
      .button--text {
        color: $cool-gray-700 !important;
      }
    }
  }
}

.card-h-full {
  min-height: 100%;
}
.field-hashes {
  .card-body {
    align-items: flex-start;
  }
}
</style>
