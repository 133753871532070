<template>
  <div
    v-if="(accountsByType && accountsByType.length > 0) || addBankAccountAllowed"
    class="counterparty-accounts"
  >
    <h3 id="add-external-account" class="!mt-12 ml-1 !mb-1">{{ title }}</h3>
    <template v-if="localAccounts">
      <CardList :flat="true" v-if="accountsByType && accountsByType.length > 0">
        <CardListItem
          class="bg-flame-white border border-surface-200 shadow shadow-surface-200/30"
          v-for="account in accountsByType"
          :key="account.virtual_account_id"
        >
          <BankAccountPaymentMethod
            @on-delete="(id) => onDeleteAccount(id)"
            @on-default="(id) => onSetDefault(id)"
            :hideRadio="true"
            :hideDelete="accountsByType.length === 1 || account.default_for_currency"
            :hideDefault="false"
            :method="{
              ...account,
              type: 'bank_account'
            }"
          />
        </CardListItem>
      </CardList>

      <Card v-if="addBankAccountAllowed" class="mt-8 pb-6 shadow-surface-200/30">
        <CounterpartyOnboardingBankAccount @add-transfer-instrument-success="loadAccounts" />
      </Card>
    </template>
    <div class="mt-4 pt-4" v-else>
      <div class="flex items-center justify-center">
        <spinner class="info mr-4" :loading="1" size="2em" />
      </div>
    </div>
  </div>
</template>

<script>
import CounterpartyMixin from '../CounterpartyMixin'
import BankAccountMixin from '../../bank/BankAccountMixin'
import PlaidMixin from '../../plaid/PlaidMixin'
import BankAccountPaymentMethod from '../../paymentMethods/BankAccountPaymentMethod.vue'
import CounterpartyOnboardingBankAccount from '@/components/payments/counterparty/onboarding/CounterpartyOnboardingBankAccount.vue'

export default {
  name: 'CounterpartyAccounts',
  props: {
    type: {
      type: String,
      default: 'payments'
    },
    title: {
      type: String,
      default: 'Bank Accounts'
    },
    merchant: {
      type: Object
    },
    accounts: {
      type: Array,
      default: () => []
    },
    addBankAccountAllowed: {
      type: Boolean,
      default: true
    }
  },
  emits: ['accounts-updated'],
  data() {
    return {
      creatingAccount: false,
      linkToken: null,
      linkTokenExpiration: null,
      localAccounts: []
    }
  },
  computed: {
    accountsByType() {
      return this.localAccounts.filter((account) => {
        if (this.type === 'payments') {
          return account.external_id && !account.external_id.startsWith('PI')
        }
        return account.external_id && account.external_id.startsWith('PI')
      })
    }
  },
  watch: {
    accounts: {
      immediate: true,
      handler(newAccounts) {
        this.localAccounts = newAccounts
      }
    }
  },
  methods: {
    /**
     * Load the virtual account bank accounts
     */
    async loadAccounts() {
      // pass what counterparty type you want to load for
      const { payload } = await this.fetchVirtualAccounts('payee')
      const accountsByType = payload.filter((account) => {
        if (this.type === 'payments') {
          return account.external_id && !account.external_id.startsWith('PI')
        }
        return account.external_id && account.external_id.startsWith('PI')
      })

      this.localAccounts = accountsByType
      this.$emit('accounts-updated', accountsByType)
    },
    /**
     * Handle the creation of a manually entered bank account
     * @param {Array} externalAccount
     */
    async handleCustomAccount(externalAccount) {
      try {
        this.creatingAccount = true
        await this.createAccount({
          bankAccount: externalAccount
        })
        await this.loadAccounts()
        this.creatingAccount = false
      } catch (e) {
        this.$store.dispatch('alert', {
          message: 'Could not set add bank account.',
          error: true
        })
        this.creatingAccount = false
      }
    },
    /**
     * Delete a bank account
     * @param {String} id
     */
    async onDeleteAccount(id) {
      try {
        const { payload } = await this.deleteAccount(id)
        if (payload.error) throw payload.error
        await this.loadAccounts()
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not delete account.',
          error: true
        })
      }
    },
    /**
     * Set bank account as default
     * @param {String} id
     */
    async onSetDefault(id) {
      try {
        await this.setDefault(id)
        await this.loadAccounts()
      } catch (e) {
        this.$store.dispatch('alert', {
          message: 'Could not set account as default.',
          error: true
        })
      }
    },
    /**
     * After plaid modal create bank account
     * @param {String} publicToken
     * @param {Object} metadata
     */
    async onPlaidSuccess({ publicToken, metadata }) {
      try {
        this.creatingAccount = true
        await this.createAccount({
          plaidToken: publicToken,
          plaidAccount: metadata.account
        })
        await this.loadAccounts()
        this.creatingAccount = false
        this.$store.dispatch('alert', {
          message: 'Bank account added'
        })
      } catch (e) {
        this.$store.dispatch('alert', {
          message: 'Could not add bank account.',
          error: true
        })
        this.creatingAccount = false
      }
    }
  },
  components: {
    BankAccountPaymentMethod,
    CounterpartyOnboardingBankAccount
  },
  mixins: [CounterpartyMixin, BankAccountMixin, PlaidMixin]
}
</script>

<style lang="scss" rel="stylesheet/scss">
.counterparty-accounts {
  .card-list-item {
    display: flex !important;
    flex-direction: row !important;
  }
  .loader {
    background: $cool-gray-400;
    width: 100%;
    height: 5rem;
  }
  section.section--container.card-section .card-section--inner {
    h4.counterparty-account--title {
      margin-bottom: 0.5em !important;
    }
  }
}
</style>
